<template>
  <div class="content">
    <div class="main">
      <div v-if="explanation" class="explanation">
        <p>Du hast nun gleich ein Zeitfenster von <strong>15 Sekunden</strong>, in dem du 3-4 Mal die Wörter, „Hilfe, Notruf!“ in das Mikrofon deines Handys sprichst.</p>
        <p>Sprich die Wörter laut und deutlich, ein wenig so, wie wenn du tatsächlich Hilfe benötigen würdest.</p>
        <p>Achte dabei darauf, das Mikrofon deines Handys nicht mit der Hand zu bedecken und halte dich exakt an die Wörter "Hilfe, Notfruf!""</p>
        <!-- Mikrofonzugriff erlaubt -->

        <div class="illustration">
          <img src="../assets/img/illustration.svg" alt="" />
        </div>
        <div class="center-element">
          <p><strong>Bist du bereit?</strong></p>
          <!-- Mikrofonzugriff erlaubt -->
        </div>
      </div>
      <div v-if="counter" class="counter center-element">
        <h1 class="center-element">{{ timerCount }}</h1>
        <p class="center-element">Sekunden verbleibend</p>

        <div class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <defs>
              <mask id="mask">
                <rect width="100%" height="8" :x="x" y="0" fill="#fff" />
              </mask>
            </defs>

            <rect class="bg" width="100%" height="8" rx="2" />

            <g mask="url(#mask)">
              <rect class="fill" width="100%" height="8" rx="2" />
            </g>
          </svg>
        </div>
      </div>
      <div v-if="counter" class="recording center-element">
        <p>Sprich wiederholt die Worte</p>
        <h2>„Hilfe, Notruf!“</h2>
        <img src="../assets/img/recording.gif" alt="" />
      </div>
      <div class="audio-player" v-if="audioRecording">
        <h3><strong>Geschafft!</strong></h3>
        <h3>Du kannst Dir deine Aufnahme noch einmal selbst anhören:</h3>

        <audio controls="controls">
          <source v-bind:src="audioRecording" type="audio/wav" />
        </audio>
        <b-button variant="outline-primary" class="trash-btn" v-if="!isRecording && audioRecording" @click="restart">Verwerfen und neue Aufnahmen starten</b-button>
        <h3>Vergiss nicht, die Aufnahme im Anschluss hochzuladen.</h3>
      </div>
    </div>
    <div class="action-bar">
      <div class="access-btn">
        <b-button v-if="!isRecording && !audioRecording" @click="recordAudio">Jetzt Aufnahme starten!</b-button>
        <!-- <b-button v-if="isRecording" @click="stopRecording">Stop</b-button> -->
        <!-- <b-button v-if="!isRecording && audioRecording" @click="recordAudio">Record</b-button> -->

        <b-button v-if="!isRecording && audioRecording" @click="upload">Aufnahme hochladen</b-button>
      </div>
    </div>

    <p>{{ feedback }}</p>
    <!-- </b-row>
    </b-container> -->
  </div>
</template>
<script>
import { getStorage, ref, uploadBytes } from "firebase/storage";

export default {
  components: {},
  data() {
    return {
      x: "-100%",
      feedback: "",
      mediaRecorder: null,
      permission: false,
      chunks: [],
      isRecording: false,
      audioRecording: null,
      blob: null,
      timerEnabled: false,
      timerCount: 15,
      explanation: true,
      counter: false,

      stream: [],
    };
  },
  mounted() {
    //this.checkPermission();
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        var x = 100 - (100 / 15) * this.timerCount;
        this.x = "-" + x + "%";
        if (value == 0) {
          this.stopRecording();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    // timerCount(value) {
    //   this.series[0] = (100 / 15) * value;
    // },
  },
  methods: {
    restart() {
      this.timerCount = 15;
      this.isRecording = false;
      this.audioRecording = null;
      this.blob = null;
      this.timerEnabled = false;
      this.explanation = true;
      this.counter = false;
    },
    upload() {
      const storage = getStorage();
      var now = Date.now();
      // console.log(now);
      var fileName = "training/batch2/training.hilfenotruf." + now + ".wav";
      const soundRef = ref(storage, fileName);

      uploadBytes(soundRef, this.blob)
        .then((snapshot) => {
          this.$router.push("Sucess");
        })
        .catch((error) => {
          this.feedback = error;
          // ...
        });
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.timerEnabled = false;
      this.counter = false;
      const tracks = this.stream.getTracks();
      tracks[0].stop();
      // this.mediaRecorder
      //   .getTracks() // get all tracks from the MediaStream
      //   .forEach((track) => track.stop()); // stop each of them
    },
    recordAudio() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // console.log("getUserMedia supported.");
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              video: false,
              audio: {
                channelCount: 1,
                echoCancellation: false,
              },
            }
          )

          // Success callback
          .then((stream) => {
            this.stream = stream;
            this.mediaRecorder = new MediaRecorder(stream);

            this.audioRecording = null;
            // console.log(this.mediaRecorder);

            this.mediaRecorder.ondataavailable = (e) => {
              // console.log(e);
              // console.log("now available");

              this.chunks.push(e.data);

              this.blob = new Blob(this.chunks, { type: "audio/wav" });
              this.chunks = [];

              const audioURL = window.URL.createObjectURL(this.blob);
              // console.log(audioURL);
              this.audioRecording = audioURL;
            };
            this.timerCount = 15;
            this.explanation = false;
            this.counter = true;
            this.mediaRecorder.start();
            this.isRecording = true;
            this.timerEnabled = true;
          })
          .catch((err) => {
            this.$bvToast.toast(err.message, {
              title: err.name,
              autoHideDelay: 5000,
              appendToast: false,
              variant: "warning",
              solid: true,
            });
            // if (err.name === "PermissionDismissedError") {
            //   // localStorage.setItem("voice_access", "prompt");
            // }
            // if (err.name === "PermissionDeniedError") {
            // }
          });
      }
    },

    check() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log("getUserMedia supported.");
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              video: false,
              audio: {
                channelCount: 1,
                echoCancellation: false,
              },
            }
          )

          // Success callback
          .then((stream) => {
            this.permission = true;
            this.feedback = "Ist bereit zur Aufnahme";
            this.mediaRecorder = new MediaRecorder(stream);
          })

          // Error callback
          .catch(function (err) {
            if (err.name === "PermissionDismissedError") {
              // localStorage.setItem("voice_access", "prompt");
            }
            if (err.name === "PermissionDeniedError") {
              // localStorage.setItem("voice_access", "denied");
            }
          });
      } else {
        console.log("getUserMedia not supported on your browser!");
      }
    },
    record() {
      // console.log(this.mediaRecorder);
      this.mediaRecorder.start();
      // console.log(this.mediaRecorder.state);
      // console.log("recorder started");
    },
  },
};
</script>
<style lang="scss">
.btn-secondary {
  color: #fff;
  background-color: #5200ff !important;
  border-color: #5200ff !important;
}

.counter {
  margin-top: 10vh;
  h1 {
    font-size: 100px;
  }
  p {
    font-size: 24px;
  }
}

.illustration {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-width: 500px;
  }
}
.trash-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 20px 0px;
}
.access-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 30px 0px;
}
.audio-player {
  audio {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0px 20px 0px;
}

.progress {
  margin-top: 10px;
  height: 10px;

  svg {
    height: 8px;
    width: 100%;

    .bg {
      fill: lightgray;
    }

    .fill {
      fill: #5200ff;
    }

    .unfilled-text {
      fill: black;
    }

    .filled-text {
      fill: white;
    }
  }
}
.recording {
  margin-top: 10vh;
  img {
    width: 100px;
  }
}
</style>
