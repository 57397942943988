import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getAuth } from "firebase/auth";

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBvAhl_My4HmzZ_s769eji6VaZR19eSWMQ",
  authDomain: "sensuu-5df0c.firebaseapp.com",
  projectId: "sensuu-5df0c",
  storageBucket: "sensuu-5df0c.appspot.com",
  messagingSenderId: "273018696954",
  appId: "1:273018696954:web:1109fffeddbf7c02778543",
};

Vue.config.productionTip = false;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
