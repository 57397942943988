<template>
  <div class="content">
    <div class="main">
      <p>Du willst uns dabei helfen, ein System zu entwickeln, mit dem allein lebende SeniorInnen weiterhin sicher in ihren eigenen vier Wänden leben können?</p>
      <!-- <p>Klasse! Dazu benötigen wir nur Dich und ein paar gesprochene Worte von Dir.</p> -->
      <p><strong>Bitte gib dazu temporär den Zugriff auf dein Mikrofon frei. Keine Angst, wir nehmen dich nicht ungefragt auf.</strong></p>
      <p>Weitere Schritte erklären wir dir gleich im Anschluss.</p>
      <div class="access-icon">
        <img src="../assets/img/micro-access.svg" alt="" />
      </div>
    </div>
    <div class="action-bar">
      <div class="access-btn">
        <b-button @click="check">Mikrofonzugriff freigeben</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getStorage, ref, uploadBytes } from "firebase/storage";
export default {
  data() {
    return {};
  },

  methods: {
    check() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log("getUserMedia supported.");
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              video: false,
              audio: {
                channelCount: 1,
                echoCancellation: false,
              },
            }
          )

          // Success callback
          .then((stream) => {
            this.$router.push({ path: "Record" });
          })

          // Error callback
          .catch((err) => {
            this.$bvToast.toast(err.message, {
              title: err.name,
              autoHideDelay: 5000,
              appendToast: false,
              variant: "warning",
              solid: true,
            });
            if (err.name === "PermissionDismissedError") {
              // localStorage.setItem("voice_access", "prompt");
            }
            if (err.name === "PermissionDeniedError") {
            }
          });
      } else {
        console.log("getUserMedia not supported on your browser!");
      }
    },
  },
};
</script>
<style lang="scss">
.btn-secondary {
  color: #fff;
  background-color: #5200ff !important;
  border-color: #5200ff !important;
}

.access-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 30px 0px;
}
.audio-player {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px 0px;
}
.access-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-outline-primary {
  color: #5200ff !important;
  background-color: transparent !important;
  border-color: #5200ff !important;
}
</style>
