<template>
  <div class="content">
    <div class="main">
      <h3>Deine Aufnahme wurde erfolgreich abgeschickt.</h3>
      <h3><strong>Vielen Dank für deine Unterstützung!</strong></h3>
      <div class="access-icon">
        <img src="../assets/img/sucess.svg" alt="" />
      </div>
    </div>
    <div class="action-bar">
      <div class="access-btn">
        <b-button @click="goToRecording">Neue Aufnahme starten</b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    //this.checkPermission();
  },
  methods: {
    goToRecording() {
      this.$router.push("Record");
    },
  },
};
</script>
<style lang="scss">
.btn-secondary {
  color: #fff;
  background-color: #5200ff !important;
  border-color: #5200ff !important;
}

.access-icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px 30px 0px;
}
.audio-player {
  width: 100%;

  padding: 20px 0px 20px 0px;
}
.access-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
